.fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    overflow: auto;
}

.fullscreen-modal-content {
    background-color: white;
    min-width: 40%;
    max-width: 80%; 
    margin: auto;
    border-radius: 1em;
    overflow: hidden;
    padding: 1em;
}
