.CalendarEventContainer {
  border: 1px solid black;
  cursor: pointer;
  transition: background 0.3s;
  min-width: 0;
}

.CalendarEventContainer:hover {
  background: rgba(50, 50, 50, 0.5);
}

.CalendarEvent .CalendarEventContainer .EventContent {
  overflow: auto;
}

.CalendarEvent .RepeatingLabel {
  border-radius: 100%;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.1em;
  left: 0.1em;
  background: rgb(255, 255, 255, 1);
  color: red;
  position: relative;
  margin-bottom: -1em;
}

.CalendarEvent .Counter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.CalendarEvent .CounterButton {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 100%;
}

.CalendarEvent .CounterButton:hover {
  background: rgba(0, 0, 0, 0.1);
}

.CalendarEvent .CompleteEvent {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CalendarEvent .CounterInterface {
  width: 40%;
  margin: auto;
}
