.new {
  height: 100%;
}

.hoverable:hover {
  cursor: pointer;
  opacity: 0.6;
}

.new-board {
  width: 50%;
}

.boards h1 {
  margin-top: 0px;
  margin-bottom: 0.5em;
}

