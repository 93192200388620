.navbar {
  background-color: #2b59c3;
  height: 100%;
  width: 100%;
  font-size: larger;
  box-shadow: 0px 2px 9px 2px #111;
  z-index: 20;
  position: relative;
}

.inner {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.padded {
  padding: 0.5em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.navbar .left {
  flex: 1;
  height: 100%;
  margin-top: -0.2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.navbar .user {
  min-width: 4em;
  height: 100%;
  height: 4em;
  overflow: hidden;
}

.navbar .home {
  cursor: pointer;
  color: #e2c6f8;
  height: 100%;
  width: 1.5em;
}

.navbar .user:hover {
  cursor: pointer;
  background-color: #0b39a3;
}

.notifications-circle {
  margin-right: 1em;
  width: 1.3em;
  height: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b00;
  color: white;
  font-size: smaller;
  border-radius: 50%;
}