.TodoList {
  max-width: 25em;
  min-width: 10em;
  max-height: 70%;
  margin: auto;
  padding: 1em 0px;
  background-color: #f5e295;
  border-radius: 3px;
  box-shadow: 3px 3px 3px 3px rgba(30, 30, 30);
  overflow: auto;
  position: relative;
  top: 1em;
}

.TodoList h1 {
  margin-top: 0px;
  margin-bottom: 1em;
}

.todo {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  margin-bottom: 1em;
}

.label {
  display: flex;
  justify-content: flex-end;
  max-height: 5em;
  overflow-y: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: manual;
}

.checkbox {
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
}

.checkbox:hover {
  cursor: pointer;
}

.bin:hover {
  cursor: pointer;
  opacity: 0.5;
}
  
.adding {
  width: 70%;
  margin: auto;
}