.board h1 {
  margin-top: 0px;
  margin-bottom: 1em;
}

.board {
  height: 100%;
}

.top-bar {
  padding: 0 2em;
  margin: 1em;
  display: flex;
  justify-content: space-between;
}

.board .top-bar-button {
  background-color: rgba(0, 0, 0, 0.33);
  border-radius: 3px;
  padding: 0.4em;
}

.board .top-bar-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.45);
}

.module {
  overflow: auto;
  font-size: larger;
  background-color: rgba(190, 0, 190, 0.2);
  transition: background-color 1s ease;
  min-height: 3em;
  width: 100%;
  max-width: 30em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.module .bookmarker {
  position: absolute;
  left: 1em;
}

.module-tag {
  display: flex;
  justify-content: space-between;
}

.board .right {
  font-size: larger;
}

.module:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 0.3s linear; 
}

.searched-user-grid {
  margin: 1em 0.5em;
  display: grid;
  grid-template-columns: 15% 35% 30% 20%;
}

.searched-user-grid img {
  object-fit: cover;
  border-radius: 1em;
}

.searched-user-profile-pic {
  height: 3em;
  width: 3em;
}

.searched-user-text {
  overflow-x: auto;
}
