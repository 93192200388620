.CalendarDisplay {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.CalendarDisplay .Columns {
  border: 0.3em inset #999;
  display: grid;
  grid-gap: 1em;
  overflow: auto;
  margin: 1em;
  flex: 1;
}

.CalendarDisplay .Columns .Column {
  border: 0.2em outset #666;
  border-radius: 0.4em;
  min-width: 10em;
  overflow: auto;
  display: flex;
  flex-flow: column;
}

.CalendarDisplay hr { 
  margin-left: 0;
  margin-right: 0;
}

.CalendarDisplay .Columns .InnerColumn {
  flex: 1;
  overflow: auto;
}

.CalendarDisplay .ColumnEventsWrapper {
  position: relative;
}

.CalendarDisplay .ColumnEvents {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
