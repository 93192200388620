.LoginDropDown {
  background-color: rgb(159, 186, 199);
  width: 70vw;
  height: 50vh;
  float: right;
  position: absolute;
  right: 0;
  box-shadow: 0px 5px 10px 5px;
  transition: all 1s ease;
  overflow: auto;
  z-index: 15;
  border-radius: 2px;
}

.cross {
  width: 2em;
  margin: auto;
}

.cross:hover {
  cursor: pointer;
  opacity: 0.5;
}
  
.login {
  padding: 5px;
}
