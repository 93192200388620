.Calendar .add-event {
    font-size: xx-large;
    height: 1.2em;
    width: 1.2em;
    background-color: white;
    border-radius: 50%;
    transition: background-color 1s ease;
    cursor: pointer;
    position: fixed;
    bottom: 1em;
    right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Calendar .add-event:hover {
    background-color: #aaa
}

.Calendar {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
}

.Calendar .Middle {
  padding-top: 1em;
  display: flex;
  flex-flow: column;
}

.Calendar .WeekStarting {
  font-size: larger;
  font-weight: bold;
}

.Calendar .TimeArrows {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Calendar .Arrow {
  cursor: pointer;
  background: #aaa;
  border-radius: 1em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Calendar .Arrow:hover {
  background: #999;
}
