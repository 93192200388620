.App {
  text-align: center;
  background-color: #ffeecf;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-size: larger;
  display: flex;
  flex-flow: column;
  min-width: 15em;
}

.header {
  height: 3em;
}

.not-header {
  overflow: auto;
  flex: 1;
  display: flex;
}

.module-switch {
  width: 100%;
  height: 100%;
}

.LoadingScreen {
  background-color: #222;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.LoadingScreen .bar-outline {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 25%;
}
