.chat {
  height: 100%;
  width: 100%;
  background-color: #ddccad;
  display: flex;
  flex-flow: column;
}

.chat h2 {
  margin: 0;
  padding: 0.5em 0;
}

.message {
  background-color: #a98670;
  overflow-y: auto;
  grid-template-columns: 20% 60% 20%;
  padding: 0.5em 0;
  max-width: 90%;
  min-width: 51%;
  border-radius: 1em;
  margin: 0.3em 0.5em;
  text-align: left;
}

.message:hover {
  cursor: pointer;
  box-shadow: 0px 1px 1px 1px;
}

.sender-date {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5em;
}

.messages {
  width: 100%;
  height: 70vh;
  overflow: auto;
  margin: auto;
  border-top: 1px solid #666;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex: 1;
}

.image-fullscreen-preview {
  height: 100%;
  width: 100%;
}

.chat-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 3px rgb(12, 250, 250);
}

.load-more {
  display: flex;
  width: 100%;
  height: 3em;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.message-sender {
  grid-column: 1;
}

.message-content {
  grid-column: 2;
  margin-right: 1em;
  margin-left: 1em;
  overflow-wrap: break-word;
}

.message-date {
  grid-column: 3;
  color: #444
}

.chat hr {
  background-color: grey;
  margin: 0;
  color: rgba(0, 0, 0, 0.3);
  max-width: 90vw;
}

.chat-title {
  overflow: hidden;
  padding: 0 0.5em;
}

.sending {
  width: 100%;
  font-size: xx-large;
}

.sending-input {
  padding: 0.1em 1em;
}