.AddEvent .SearchResults {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin-bottom: 0.4em;
  height: 3em;
  align-items: center;
}

.AddEvent img {
  height: 3em;
  width: 3em;
}

.AddEvent .Advanced {
  transition: max-height 0.4s;
  overflow: hidden;
}
