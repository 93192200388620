.profile {
  max-width: 60em;
  margin: auto;
}
.profile-info {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row: auto auto;
  grid-row-gap: 1em;
}

.profile i {
  font-size: smaller;
}

.profile-heading {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.profile-heading img {
  object-fit: cover;
  border-radius: 1em;
}

.image-preview {
  height: 6em;
  width: 6em;
  margin: auto;
  border-radius: 1em;
}

.image-preview img {
  object-fit: cover;
  border-radius: 1em;
}

.profile .image-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.profile .upload-pic-icon {
  position: relative;
  float: right;
  top: 0.5em;
}

.profile-pic {
  height: 12em;
  width: 12em;  
  border-radius: 1em;
  cursor: pointer;
}

.profile-pic:hover {
  opacity: 0.85;
}

.profile label {
  cursor: pointer;
}