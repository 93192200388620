.Home {
}

.Home .Bookmark {
  cursor: pointer;
  width: 50%;
  height: 2em;
  background: rgba(50, 0, 0, 0.2);
  margin: auto;
  transition: background 0.2s ease-in-out;
  transition: border-radius 3.2s ease-in-out;
  border-radius: 0.3em;
}

.Home .Bookmark:hover {
  background: rgba(50, 0, 0, 0.4);
  border-radius: 2em;
}
