.text-input-field {
  display: block;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid;
  margin: auto;
  font-size: large;
  word-break: break-all;
  hyphens: auto;
  overflow-y: auto;
  resize: none;
  max-height: 8em;
  width: 100%;
  border-radius: 5px;
  outline: none;
  font-size: 1.3rem;
  transition: all 0.5s ease-in-out;
  height: 1.5em;
}

.text-input-button {
  float: left;
  background-color: inherit; 
  border-radius: 1em;
  border: 1px solid black;
}

.image-upload {
  padding-right: 0.2em;
}

.text-input .uploaded:hover {
  cursor: pointer;
  opacity: 0.6;
}

.image-upload:hover {
  cursor: pointer;
  opacity: 0.6;
}

.cancel-upload {
  display: inline-block;
  position: relative;
  right: 15px;
  bottom: 35px;
}

.image-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.text-input-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.text-input {
  display: flex;
  width: 100%;
}