.board-tag {
  font-size: larger;
  background-color: rgba(190, 190, 0, 0.2);
  transition: background-color 1s ease;
  min-height: 3em;
  max-width: 30em;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.board-tag:hover {
  cursor: pointer;
  background-color: rgba(150, 150, 0, 0.2);
  transition: background-color 0.3s linear; 
}

.disabled {
  background-color: grey;
}

.disabled:hover {
  cursor: wait;
}