.sidebar {
  position: absolute;
  z-index: 10;
  width: 100vw;
  height: calc(100vh - 3em);
  background-color: #ede3e4;
  overflow-y: auto;
}

.sidebar-content {
  padding-bottom: 3em;
  padding-top: 1em;
}

.sidebar-controller {
  position: fixed;
  height: 100vh;
  width: 0vw;
}
